/*
 * @Author: wxb
 * @Date: 2021-06-30 00:36:59
 * @LastEditTime: 2021-08-11 11:19:18
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\api\project\index.js
 */
import request from '@/utils/request'
import qs from 'qs'
/**
 * @description: 获取原始公告信息列表
 * @param {*} params
 * @return {*}
 */
export function getYsggxxList(params) {
  return request({
    url: '/api/company/projectWin/getYsggxxList',
    method: 'get',
    params
  })
}

/**
 * @description: 获取精编公告列表信息
 * @param {*} params
 * @return {*}
 */
export function getProjectNoticeList(params) {
  return request({
    // url: '/api/company/loginProjectWin/getProjectNoticeList',
    url: '/api/company/projectWin/getProjectNoticeList',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description:  获取精编项目公告详情
 * @param {*} params
 * @return {*}
 */
export function getProjectNoticeDetails(params) {
  return request({
    url: '/api/company/loginProjectWin/getProjectNoticeDetails',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 获取项目列表
 * @param {*} params
 * @return {*}
 */
export function getProjectList(params) {
  return request({
    url: '/api/company/projectWin/getProjectList',
    method: 'get',
    params
  })
}

/**
 * @description: 获取项目详情
 * @param {*} params
 * @return {*}
 */
export function getProjectDetails(params) {
  return request({
    url: '/api/company/loginProjectWin/getProjectDetails',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 根据项目ID查询出项目审批公示数据
 * @param {*} params
 * @return {*}
 */
export function getProjectfiling(params) {
  return request({
    url: '/api/company/loginProjectWin/getProjectfiling',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 根据项目ID获取项目批复公示数据
 * @param {*} params
 * @return {*}
 */
export function getProjectapproval(params) {
  return request({
    url: '/api/company/loginProjectWin/getProjectapproval',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 根据项目ID获取项目公告数据
 * @param {*} params
 * @return {*}
 */
export function getPreliminarydesign(params) {
  return request({
    url: '/api/company/loginProjectWin/getPreliminarydesign',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 根据根项目ID获取子级项目数据
 * @param {*} params
 * @return {*}
 */
export function getprojectImplementation(params) {
  return request({
    url: '/api/company/loginProjectWin/getprojectImplementation',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 获取拟建项目列表
 * @param {*} params
 * @return {*}
 */
export function getProposedProjectList(params) {
  return request({
    url: '/api/company/loginProjectWin/getProposedProjectList',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 获取拟建项目信息
 * @param {*} params
 * @return {*}
 */
export function getProposedProjectDetails(params) {
  return request({
    url: '/api/company/loginProjectWin/getProposedProjectDetails',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 获取数据的总数量
 * @param {*} params
 * @return {*}
 */
export function getDataNums(params) {
  return request({
    url: '/api/company/projectWin/getDataNums',
    method: 'get',
    params
  })
}
